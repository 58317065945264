<template>
	<div id="tu-course">
		<el-row :gutter="20">
			<el-col :span="4">
		    <SecondMenu />
			</el-col>
			<el-col :span="20">
				<div class="global-search">
					<div class="global-search__title">{{ $t('search') }}</div>
					<div class="global-search__warp">
						<el-form :inline="true" class="global-search__warp__inline">
							<el-form-item :label="$t('order-sn')">
								<el-input v-model="params.order_sn" placeholder="Please input" />
							</el-form-item>
							<!-- <el-form-item :label="$t('status')">
								<el-select v-model="params.stat" placeholder="Select" clearable filterable>
									<el-option
										v-for="item, index in tab"
										:key="item.value"
										:label="$t(item.name)"
										:value="item.value"
									/>
								</el-select>
							</el-form-item> -->
							<el-form-item v-if="!isStudent" :label="$t('select-student')">
								<el-select v-model="params.profile_id" placeholder="Select" clearable filterable>
									<el-option 
										v-for="item, index in studentList"
										:key="item.profile_id"
										:label="item.nickname"
										:value="item.profile_id"
									/>
								</el-select>
							</el-form-item>
							<el-form-item v-else :label="$t('select-teacher')">
								<el-select v-model="params.uid" placeholder="Select" clearable filterable>
									<el-option 
										v-for="item, index in teacherList"
										:key="item.uid"
										:label="item.nickname"
										:value="item.uid"
									/>
								</el-select>
							</el-form-item>
							<el-form-item :label="$t('sort')">
								<el-select v-model="params.sort" placeholder="Select" clearable filterable>
									<el-option
										v-for="item, index in sort"
										:key="item.value"
										:label="$t(item.name)"
										:value="item.value"
									/>
								</el-select>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="onSearchBtn">{{ $t('search') }}</el-button>
								<el-button v-if="userType==2" type="danger" @click="handleBookCourseOpen">{{ $t('book-course') }}</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
				<!-- <div class="tu-title">
					<div style="padding: 9px 0px;">Lessons</div>
					<div><el-button v-if="userType==2" size="medium" type="danger" @click="handleBookCourseOpen">预定课程</el-button></div>
				</div> -->
				<div class="tu-table">
					<div class="tu-tab">
						<el-radio-group v-model="params.stat" :label="$t('status')" @change="onRadioStatChange">
							<el-radio-button v-for="item, index in tab" :label="item.value">{{ $t(item.name) }}</el-radio-button>
						</el-radio-group>
					</div>
					<el-table 
						v-if="tableData.length > 0 || loading"
						v-loading="loading"
						element-loading-text="Loading..."
						:data="tableData" 
						border 
						:show-header="showHeader">
						<el-table-column prop="me" :label="$t('Learner & Order Sn')" v-if="userType==1">
							<template #default="scope">
								<div class="tu-nickname">{{ scope.row.me_info.nickname }}</div>
								<div class="tu-subject">{{ scope.row.order_sn }}</div>
							</template>
					    </el-table-column>
					    <el-table-column prop="name" :label="$t('Name & Subject')">
					    	<template #default="scope">
								<div class="tu-nickname">{{ scope.row.user_info.nickname }}</div>
								<div class="tu-subject">{{ scope.row.subject.subject_name }}({{ scope.row.subject.level_name }})</div>
							</template>
					    </el-table-column>
					    <el-table-column prop="date" :label="$t('Date & Time')">
					    	<template #default="scope">
									<div class="tu-date">{{ handleDateShow(scope.row) }}</div>
									<div class="tu-time">{{ handleTimeShow(scope.row) }}</div>
								</template>
					    </el-table-column>
					    <el-table-column prop="other" :label="$t('Price & Status')">
					    	<template #default="scope">
									<div class="tu-price">{{ scope.row.currency }}{{ scope.row.price }}</div>
									<div class="tu-status">{{ scope.row.status_name }}</div>
								</template>
					    </el-table-column>
					    <el-table-column width="120" align="center" :label="$t('Operations')">
					      <template #default="scope">
					        <el-button type="primary" size="mini" @click="handleDetail(scope.$index, scope.row)">
					        	{{ $t('detail') }}
					        </el-button>
					      </template>
					    </el-table-column>
				  	</el-table>	
				  	<div v-else>
							<el-empty :description="$t('No Data')"></el-empty>
						</div>
				  	<div class="tu-pagination">
				  		<el-pagination 
				  			:hide-on-single-page="true" 
				  			background 
				  			layout="prev, pager, next" 
				  			:total="params.total"
				  			:page-size="20"
		  					@current-change="handlePageChange">
				  		</el-pagination>
				  	</div>
				</div>
				
			</el-col>
		</el-row>
		<BookCourse ref="bookCourse" @change="handleBookClose" />
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import SecondMenu from '@/components/SecondMenu'
import BookCourse from '@/components/BookCourse'
import CourseApi from '@/api/course'
import TimeControl from '@/utils/time-control'

export default {
	name: "Course",
	components: {
		SecondMenu,
		BookCourse,
		TimeControl
	},
	data() {
		return {
			loading: true,
			showHeader: true,
			tableData: [],
			params: {
				uid: null,
				profile_id: "",
				order_sn: "",
				page: 1,
				stat: 7,
				total: 0,
				sort: ''
			},
			tabSet: null,
			tab: [
				{ name: 'tab-status.processing', value: 7 },
				{ name: 'tab-status.completed', value: 4 },
				{ name: 'tab-status.cancelled', value: 5 },
				// { name: 'tab-status.closed', value: 6 },
				{ name: 'tab-status.all', value: 0 },
			],
			sort: [
				{ name: 'search-option.book-course-time-from-low-to-high', value: 're_book_time asc'},
				{ name: 'search-option.book-course-time-from-high-to-low', value: 're_book_time desc'}
			]
		}
	},
	computed: {
		...mapGetters([
			'userInfo', 'userType', 'isStudent', 'studentList', 'teacherList'
		])
	},
	created() {
		this.getApiListData()
	},
	methods: {
		handleBookCourseOpen() {
			this.$refs.bookCourse.open();
		},
		handleBookClose() {
			this.params.page = 1;
			this.params.stat = 7;
			this.getApiListData();
		},
		handleDetail(index, row) {
			let rowId = row.id;
			this.$router.push({ path: `/course/${rowId}` })
		},
		handlePageChange(page) {
			this.loading = true
			this.params.page = page || 1;
			this.getApiListData();
		},
		handleDateShow(row) {
			let book_time = row.book_time * 1000;
			return TimeControl.getFormatTime(book_time)
		},
		handleTimeShow(row) {
			let book_time = row.book_time * 1000;
			let book_end_time = (row.book_time + row.second) * 1000;
			return TimeControl.getFormatTime(book_time, "HH:mm") + ' ~ ' + TimeControl.getFormatTime(book_end_time, "HH:mm")
		},
		getApiListData() {
			CourseApi.index(this.params).then((res) => {
				this.loading = false
				this.tableData = res.data
				this.params.total = res.meta.pagination.total
			});
		},
		onSelectTab(value) {
			this.params.stat = value.value;
			this.params.page = 1;
			this.loading = true;
			this.getApiListData();
		},
		onSearchBtn() {
			this.params.page = 1;
			this.loading = true;
			this.getApiListData();
		},
		onRadioStatChange() {
			if(!!this.tabSet) {
				clearTimeout(this.tabSet);
			}
			this.tabSet = setTimeout(() => {
				this.params.page = 1;
				this.loading = true;
				this.getApiListData();
			}, 500)
		}
	}
}
</script>
<style lang="less">
#tu-course {
	.tu-title {
		background: var(--el-color-primary);
	  padding: 8px 15px;
	  color: var(--el-color-white);
	  text-align: left;
	  font-weight: 700;
	  display: flex;
	  flex-direction: row;
	  justify-content: space-between;
	  align-items: center;
	}

	.tu-search {
		padding: 15px 0 0 0;
	}

	.tu-tab {
		// display: flex;
		// align-items: center;
		// justify-content: space-around;
		// margin: 10px 0;
		margin-bottom: 10px;

		// & > div {
		// 	width: 15%;
		// 	text-align: center;
		// 	padding: 10px 0;
		// 	border-radius: 5px;
		// 	background: var(--el-color-info);
		// 	color: #FFF;
		// 	cursor: pointer;

		// 	-moz-user-select:none; /*火狐*/
		// 	-webkit-user-select:none; /*webkit浏览器*/
		// 	-ms-user-select:none; /*IE10*/
		// 	user-select:none;
		// }
	}

	.tu-table {
		.tu-nickname {
			color: var(--el-color-primary);
			font-size: 16px;
			font-weight: 600;
			padding: 6px 0;
		}

		.tu-subject, .tu-time, .tu-status {
			color: var(--el-color-info);
		}

		.tu-date {
			color: var(--el-color-primary);
			font-size: 16px;
			font-weight: 400;
			padding: 6px 0;
		}

		.tu-price {
			color: var(--el-color-primary);
			font-size: 16px;
			font-weight: 400;
			padding: 6px 0;
		}
	}

	.tu-pagination {
		padding: 20px 0;
	}
}
</style>